var STAGING_BRANCH = 'master';
var VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV;
var VERCEL_GIT_COMMIT_REF = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF;
export var isPreview = function isPreview() {
  return VERCEL_ENV === 'preview' && VERCEL_GIT_COMMIT_REF !== STAGING_BRANCH;
};
export var isProduction = function isProduction() {
  return VERCEL_ENV === 'production';
};
export var isStaging = function isStaging() {
  return VERCEL_ENV === 'preview' && VERCEL_GIT_COMMIT_REF === STAGING_BRANCH;
};
export var getEnv = function getEnv() {
  if (isPreview()) return 'preview';
  if (isProduction()) return 'production';
  if (isStaging()) return 'staging';
  return 'development';
};
export var isDevelopment = function isDevelopment() {
  return getEnv() === 'development';
};
export var getRelease = function getRelease() {
  return process.env.VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "local-development-".concat(new Date().getTime());
};
export var getIsSentryEnabled = function getIsSentryEnabled() {
  return (isStaging() || isProduction()) && !process.env.NEXT_DISABLE_SENTRY;
};