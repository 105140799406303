import WebkitCallback from './webkit-callback';
var change = function change(fullScreen) {
  WebkitCallback.fullScreenChange(fullScreen);
};

// Newer version of the function handling different screen orientations
var enterLandscapeFullScreeenMobile = function enterLandscapeFullScreeenMobile() {
  WebkitCallback.fullScreenEnterMobile('landscape');
};
var exitLandscapeFullScreenMobile = function exitLandscapeFullScreenMobile() {
  WebkitCallback.fullScreenExitMobile();
};
export default {
  change: change,
  enterLandscapeFullScreeenMobile: enterLandscapeFullScreeenMobile,
  exitLandscapeFullScreenMobile: exitLandscapeFullScreenMobile
};