// Language keys follow ISO 639-1
// https://www.w3schools.com/tags/ref_language_codes.asp
export var LANGUAGES = {
  cs: {
    enabled: true,
    english: 'Czech',
    "native": 'Čeština'
  },
  da: {
    enabled: true,
    english: 'Danish',
    "native": 'Dansk'
  },
  de: {
    enabled: true,
    english: 'German',
    "native": 'Deutsch'
  },
  en: {
    enabled: true,
    english: 'English',
    "native": 'English'
  },
  es: {
    enabled: true,
    english: 'Spanish',
    "native": 'Español'
  },
  fi: {
    enabled: true,
    english: 'Finnish',
    "native": 'Suomi'
  },
  fr: {
    enabled: true,
    english: 'French',
    "native": 'Français'
  },
  hr: {
    enabled: true,
    english: 'Croatian',
    "native": 'Hrvatski'
  },
  hu: {
    enabled: true,
    english: 'Hungarian',
    "native": 'Magyar'
  },
  it: {
    enabled: true,
    english: 'Italian',
    "native": 'Italiano'
  },
  lt: {
    enabled: true,
    english: 'Lithuanian',
    "native": 'Lietuvis'
  },
  lv: {
    enabled: true,
    english: 'Latvian',
    "native": 'Latvietis'
  },
  nb: {
    enabled: true,
    english: 'Norwegian Bokmål',
    "native": 'Norsk, bokmål'
  },
  nl: {
    enabled: true,
    english: 'Dutch',
    "native": 'Nederlands'
  },
  nn: {
    enabled: true,
    english: 'Norwegian Nynorsk',
    "native": 'Norsk nynorsk'
  },
  pl: {
    enabled: true,
    english: 'Polish',
    "native": 'Polski'
  },
  pt: {
    enabled: true,
    english: 'Portuguese',
    "native": 'Português'
  },
  ro: {
    enabled: true,
    english: 'Romanian',
    "native": 'Română'
  },
  ru: {
    enabled: true,
    english: 'Russian',
    "native": 'Русский'
  },
  se: {
    enabled: true,
    english: 'Northern Sámi',
    "native": 'Davvisámegiella'
  },
  sq: {
    enabled: true,
    english: 'Albanian',
    "native": 'Shqip'
  },
  sr: {
    enabled: true,
    english: 'Serbian',
    "native": 'Srpski'
  },
  sv: {
    enabled: true,
    english: 'Swedish',
    "native": 'Svenska'
  },
  tr: {
    enabled: true,
    english: 'Turkish',
    "native": 'Türkçe'
  }
};